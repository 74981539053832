import { render, staticRenderFns } from "./NowBreadcrumbs.vue?vue&type=template&id=9c8c44f8&scoped=true&"
import script from "./NowBreadcrumbs.vue?vue&type=script&lang=js&"
export * from "./NowBreadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./NowBreadcrumbs.vue?vue&type=style&index=0&id=9c8c44f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c8c44f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowIcon: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowIcon.vue').default,NowSkeletonLoader: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowSkeletonLoader.vue').default})
