import { render, staticRenderFns } from "./organization.vue?vue&type=template&id=600f7409&scoped=true&"
import script from "./organization.vue?vue&type=script&lang=js&"
export * from "./organization.vue?vue&type=script&lang=js&"
import style0 from "./organization.vue?vue&type=style&index=0&id=600f7409&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600f7409",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowOrganizationNav: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowOrganizationNav.vue').default,NowSessionModal: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowSessionModal.vue').default,NowOrganizationSidebar: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowOrganizationSidebar.vue').default,NowContainer: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowContainer.vue').default,NowPortals: require('/home/forge/app.tavasta.com/releases/20250121130215/components/NowPortals.vue').default})
