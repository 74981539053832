import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d26bad0c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4aad771f = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _116fec62 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _39be23b0 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _378ee64e = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _139b2258 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _7aeeaf94 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _40053a20 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _0c82be26 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _61a00478 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _c6bb4948 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _33dc27cc = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _c5bfaec6 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _79039810 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _bfc98fde = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _071c7a48 = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _76dc826d = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _1321932e = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _00c0846d = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _47966cbc = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _6b869348 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _be56f2ba = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _a1972e70 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _9a8fa3f4 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _1c4c37f6 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _ea3cc236 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _0978447f = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _1b3d67ba = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _7ee61572 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _3fe40ba3 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _5f696803 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _4114db98 = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _3e423438 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _3a1b04f8 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _26e8d89e = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _2a912ff6 = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _31a7ea8a = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _33f295da = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _365fab8e = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _1dcfffbf = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _7f6ca5f0 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _4da49ed8 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _61b7a0d8 = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _0ed85a88 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _4b7b3e0e = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _b31efe48 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _d26bad0c,
    name: "account"
  }, {
    path: "/action",
    component: _4aad771f,
    name: "action"
  }, {
    path: "/archive",
    component: _116fec62,
    name: "archive"
  }, {
    path: "/audit",
    component: _39be23b0,
    name: "audit"
  }, {
    path: "/deviation",
    component: _378ee64e,
    name: "deviation"
  }, {
    path: "/it",
    component: _139b2258,
    name: "it"
  }, {
    path: "/login",
    component: _7aeeaf94,
    name: "login"
  }, {
    path: "/logout",
    component: _40053a20,
    name: "logout"
  }, {
    path: "/offline",
    component: _0c82be26,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _61a00478,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _c6bb4948,
    name: "organization"
  }, {
    path: "/register",
    component: _33dc27cc,
    name: "register"
  }, {
    path: "/report",
    component: _c5bfaec6,
    name: "report"
  }, {
    path: "/risk",
    component: _79039810,
    name: "risk"
  }, {
    path: "/search",
    component: _bfc98fde,
    name: "search"
  }, {
    path: "/styles",
    component: _071c7a48,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _76dc826d,
    name: "suggestion"
  }, {
    path: "/task",
    component: _1321932e,
    name: "task"
  }, {
    path: "/unit",
    component: _00c0846d,
    name: "unit"
  }, {
    path: "/verify",
    component: _47966cbc,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _6b869348,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _be56f2ba,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _a1972e70,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _9a8fa3f4,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _1c4c37f6,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _ea3cc236,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _0978447f,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _1b3d67ba,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _7ee61572,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _3fe40ba3,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _5f696803,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _4114db98,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _3e423438,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _3a1b04f8,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _26e8d89e,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _2a912ff6,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _31a7ea8a,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _33f295da,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _365fab8e,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _1dcfffbf,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _7f6ca5f0,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _4da49ed8,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _61b7a0d8,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _0ed85a88,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _4b7b3e0e,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _b31efe48,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
